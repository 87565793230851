import React from 'react'
import { NavBar, TabBar } from 'antd-mobile'
import {
    Route,
    Routes,
    useNavigate,
    useLocation,
    BrowserRouter as Router,
} from 'react-router-dom'
import {
    AppOutline,
    BankcardOutline,
    UnorderedListOutline,
    SetOutline,
} from 'antd-mobile-icons'

import './index.scss'
import Order from '../order'
import ChangeOrder from '../order/changeOrder'
import OrderDetail from '../../components/order/orderInfoDetail'
import ShiftsOrderDetail from '../../components/order/orderShiftsDetail'
import Event from '../card'
import EventDetail from '../../components/order/eventOrderDetail'
import Control from '../station/vehicleControl'
import ControlDetail from '../../components/station/vehicleControl'
import EventPackageOrCoupon from '../card/eventPackageOrCoupon'
import EventPackageOrCouponDetail from '../../components/card/eventPackageOrCouponDetail'
import EventSls from '../tool/eventSls'
import EventSlsDetail from '../../components/tool/eventSlsDetail'
import EventOrder from '../order/eventOrder'
import RentRecord from '../order/rentOrder'
import RentRecordDetil from '../../components/order/rentOrder'
import EventLogin from '../tool/eventLogin'
import EventLoginDetail from '../../components/tool/eventLoginDetail'
import EventDeviceDetail from '../../components/station/eventDeviceDetail'
import EventDevice from '../station/eventDevice'
import Vehicle from '../station/station'
import StationDetail from '../../components/station/station'
import Station from '../../pages/station'
import CardCoupons from '../../pages/card'
import Tool from '../../pages/tool'
import FloatingBubbleHome from '../../components/common/FloatingBubbleHome'
import Feedback from '../feedback'
import PackageCouponPromotion from '../card/packageCouponPromotion'
import PackageCouponPromotionDetail from '../../components/card/PackageCouponPromotionDetail'
import StationModal from '../../components/station/station/stationModal'
import EventAppDevice from '../tool/eventAppDevice'
import EventAppDeviceDetail from '../../components/tool/EventAppDeviceDetail'
import SlsClock from '../tool/slsClock'
import SlsClockDetail from '../../components/tool/SlsClockDetail'
import PermissionCheck from '../tool/permissionCheck'
import PermissionCheckDetail from '../../components/tool/permissionCheckDetail'
import StationDistance from '../tool/stationDistance'
import StationDistanceDetail from '../../components/tool/stationDistanceDetail'
import CasualClock from '../tool/casualClock'
import CasualClockDetail from '../../components/tool/casualClockDetail'
import Contract from '../tool/contract'
import ContractDetail from '../../components/tool/contractDetail'
import AramsDetail from '../../components/tool/aramsDetail'

const Bottom = () => {
    const history = useNavigate()
    const location = useLocation()
    const { pathname } = location

    const setRouteActive = (value) => {
        history(value)
    }

    const tabs = [
        {
            key: '/station',
            title: '车站查询',
            icon: <AppOutline />,
        },
        {
            key: '/order',
            title: '订单查询',
            icon: <UnorderedListOutline />,
        },
        {
            key: '/card',
            title: '营销查询',
            icon: <BankcardOutline />,
        },
        {
            key: '/tool',
            title: '工具查询',
            icon: <SetOutline />,
        },
    ]

    return (
        <div className='tabBut'>
            <TabBar activeKey={pathname} onChange={value => setRouteActive(value)} >
                {tabs.map(item => (
                    <TabBar.Item am-tab-bar-bar key={item.key} icon={item.icon} title={item.title} />
                ))}
            </TabBar>
        </div>
    )
}

const Home = () => {

    return (
        <Router safeArea={true} initialEntries={['/order']}>
            <div className="app">
                <div className="top navbar-container" >
                    <NavBar back={null} style={{ background: 'white' }} >技术支持-易易产品测试部</NavBar>
                </div>
                <div className="body">
                    <Routes>
                        <Route exact path='/' element={<Station />} />=
                        <Route exact path='/order' element={<Order />} />
                        <Route exact path='/order/change' element={<ChangeOrder />} />
                        <Route path='/order/detail' element={<OrderDetail />} />
                        <Route path='/order/shiftsDetail' element={<ShiftsOrderDetail />} />
                        <Route exact path='/order/rent' element={<RentRecord />} />
                        <Route exact path='/order/rent/detail' element={<RentRecordDetil />} />
                        <Route exact path='/event' element={<Event />} />
                        <Route exact path='/event/order' element={<EventOrder />} />
                        <Route path='/event/order/detail' element={<EventDetail />} />
                        <Route path='/event/package/coupon' element={<EventPackageOrCoupon />} />
                        <Route path='/event/package/coupon/detail' element={<EventPackageOrCouponDetail />} />
                        <Route path='/event/logsls' element={<EventSls />} />
                        <Route path='/event/logsls/detail' element={<EventSlsDetail />} />
                        <Route path='/event/login' element={<EventLogin />} />
                        <Route path='/event/login/detail' element={<EventLoginDetail />} />
                        <Route path='/event/device' element={<EventDevice />} />
                        <Route path='/event/device/detail' element={<EventDeviceDetail />} />
                        <Route path='/event/app/device' element={<EventAppDevice />} />
                        <Route path='/event/app/device/detail' element={<EventAppDeviceDetail />} />
                        <Route exact path='/control' element={<Control />} />
                        <Route exact path='/control/detail' element={<ControlDetail />} />
                        <Route exact path='/station' element={<Station />} />
                        <Route exact path='/station/chart' element={<Vehicle />} />
                        <Route exact path='/station/chart/detail' element={<StationDetail />} />
                        <Route exact path='/card' element={<CardCoupons />} />
                        <Route exact path='/package/coupon/check/promotion' element={<PackageCouponPromotion />} />
                        <Route exact path='/package/coupon/check/promotion/detail' element={<PackageCouponPromotionDetail />} />
                        <Route exact path='/tool' element={<Tool />} />
                        <Route exact path='/feedback' element={<Feedback />} />
                        <Route exact path='/station/feedback' element={<StationModal />} />
                        <Route path='/sls/clock' element={<SlsClock />} />
                        <Route path='/sls/clock/detail' element={<SlsClockDetail />} />
                        <Route path='/permission/Check' element={<PermissionCheck />} />
                        <Route path='/permission/Check/detail' element={<PermissionCheckDetail />} />
                        <Route path='/station/home/distance' element={<StationDistance />} />
                        <Route path='/station/home/distance/detail' element={<StationDistanceDetail />} />
                        <Route path='/casual/clock' element={<CasualClock />} />
                        <Route path='/casual/clock/detail' element={<CasualClockDetail />} />
                        <Route path='/contract' element={<Contract />} />
                        <Route path='/contract/detail' element={<ContractDetail />} />
                        <Route path='/arams/deal' element={<AramsDetail />} />
                    </Routes>
                    <FloatingBubbleHome />
                </div>
                <div className="bottom">
                    <Bottom />
                </div>
            </div>
        </Router>
    )
}
export default Home