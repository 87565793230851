import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { contractVinInfoApi } from '../../../service/tool/contract'
import { Collapse, Toast, Divider } from 'antd-mobile'

// import './index.scss'

const ContractDetail = () => {
    const [data, setData] = useState([])
    const history = useNavigate();
    const [search, setSearch] = useSearchParams()  // eslint-disable-line no-unused-vars

    const contractVinInfoData = (value) => {

        contractVinInfoApi(value).then(res => {
            Toast.clear()
            if (res.code === 400) {
                Toast.show({
                    icon: 'fail',
                    content: res.msg,
                })
                history('/contract')
            } else if (res.code === 200) {
                if (res.data.length > 0) {
                    setData(res.data)
                } else {
                    Toast.show({
                        icon: 'fail',
                        content: '数据为空',
                    })
                    history('/contract')
                }
            } else {
                Toast.show({
                    icon: 'fail',
                    content: '未知错误',
                })
                history('/contract')
            }

        }).catch(
            err => {
                Toast.show({
                    icon: 'fail',
                    content: '未知错误',
                })
                history('/contract')
            }
        )
    }

    useEffect(() => {
        try {
            Toast.show({
                icon: 'loading',
                duration: 0,
                content: '加载中…',
            })
            contractVinInfoData({ "plateNo": search.get('plateNo') === "undefined" ? "" : search.get('plateNo'), "vin": search.get('vin') === "undefined" ? "" : search.get('vin') })
        } catch (e) {
            Toast.show({
                icon: 'fail',
                content: '未知错误',
            })
            history('/contract')
        }
    }, [])//eslint-disable-line 

    return (
        <div className='topbackGroupClocl'>
            {
                data && <>
                    <Collapse defaultActiveKey={['1']}>
                        <Collapse.Panel key='1' title='合约校验信息'>
                            {
                                data.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            <p>车牌号：{item.plateNo}</p>
                                            <p>Vin：{item.vin}</p>
                                            <p>车辆标签：{item.tag}</p>
                                            <p>合同名称：{item.contractName}</p>
                                            <p>合同编号：{item.contractNo}</p>
                                            <p>合同类型：{item.contractType}</p>
                                            <p>签约人：{item.signPerson}</p>
                                            <p>签约手机号：{item.signerPhone}</p>
                                            <p>合同状态：{item.contractStatus}</p>
                                            <p>合同来源：{item.contractSource}</p>
                                            <p>租金名称：{item.rentName}</p>
                                            <p>方案名称：{item.programmeName}</p>
                                            <p>合同页面位置：{item.contractPageLocation}</p>
                                            {
                                                data.length - 1 !== index && <Divider />
                                            }
                                        </div>
                                    )
                                })}

                        </Collapse.Panel>
                    </Collapse>
                </>
            }
        </div>
    )
}
export default ContractDetail;