import React from 'react'
import { Space, Image, Card } from 'antd-mobile'
import { useNavigate } from 'react-router-dom'
import { User, Log, Iphone, BigClock, Permissions, MapDistance, BellRing, Dianziqian } from '@icon-park/react';
import srcImage from '../../static/img/station_default.png'


import './index.scss'


const Tool = function () {
    const history = useNavigate();

    const eventAppNewCodeData = () => {
        history('/event/login')
    }

    const eventSlsData = () => {
        history('/event/logsls')
    }
    const eventAppDeviceData = () => {
        history('/event/app/device')
    }

    // const docManualData = () => {
    //     window.open('https://hd-prod-api.geelytech.com/oss-api/file/info?fileKey=950742f1a626475a9041ac11235e76f5&dt=inline')
    // }
    const slsClockData = () => {
        history('/sls/clock')
    }

    const PermissionCheckData = () => {
        history('/permission/Check')
    }

    const StationHomeDistanceData = () => {
        history('/station/home/distance')
    }

    const CasualClockData = () => {
        history('/casual/clock')
    }

    const ContractData = () => {
        history('/contract')
    }



    return (
        <>
            <div className='orderOCouponTool'>
                <Image src={srcImage} lazy />
                <Card className='card'>
                    <Space justify='start' direction='vertical' align='center' className='imageTop package' onClick={eventSlsData}>
                        <Log theme="outline" size="30" fill="#4a90e2" />
                        <p className='ordePackage'>日志查询</p>
                    </Space>
                    <Space justify='start' direction='vertical' align='center' className='imageTop package' onClick={eventAppNewCodeData}>
                        <User theme="outline" size="30" fill="#4a90e2" />
                        <p className='ordePackage'>app验证码</p>
                    </Space>
                    <Space justify='start' direction='vertical' align='center' className='imageTop package' onClick={eventAppDeviceData}>
                        <Iphone theme="outline" size="30" fill="#4a90e2" />
                        <p className='ordePackage'>设备信息</p>
                    </Space>
                    <Space justify='start' direction='vertical' align='center' className='imageTop package' onClick={slsClockData}>
                        <BigClock theme="outline" size="30" fill="#4a90e2" />
                        <p className='ordePackage'>打卡定位</p>
                    </Space>
                    <Space justify='start' direction='vertical' align='center' className='imageTop package' onClick={PermissionCheckData}>
                        <Permissions theme="outline" size="30" fill="#4a90e2" />
                        <p className='ordePackage'>权限校验</p>
                    </Space>
                    <Space justify='start' direction='vertical' align='center' className='imageTop package' onClick={StationHomeDistanceData}>
                        <MapDistance theme="outline" size="30" fill="#4a90e2" />
                        <p className='ordePackage'>换电站定位</p>
                    </Space>
                    <Space justify='start' direction='vertical' align='center' className='imageTop package' onClick={CasualClockData}>
                        <BellRing theme="outline" size="30" fill="#4a90e2" />
                        <p className='ordePackage'>临时加班</p>
                    </Space>
                    <Space justify='start' direction='vertical' align='center' className='imageTop package' onClick={ContractData}>
                        <Dianziqian theme="outline" size="30" fill="#4a90e2" />
                        <p className='ordePackage'>车辆合约</p>
                    </Space>
                    {/* 
                    <Space justify='start' direction='vertical' align='center' className='imageTop package' onClick={docManualData}>
                        <Agreement theme="outline" size="30" fill="#4a90e2" />
                        <p className='ordePackage'>用户手册</p>
                    </Space> */}
                </Card>
            </div>
        </>
    )
}
export default Tool