import React from 'react'
import { Form, Input, Button, Space, Toast } from 'antd-mobile'
import { useNavigate } from 'react-router-dom'


const Contract = function () {
    const [form] = Form.useForm()
    const history = useNavigate();

    const onFinish = (value) => {

        Toast.show({
            icon: 'loading',
            duration: 0,
            content: '加载中…',
        })
        history(`/contract/detail?&plateNo=${value.plateNo}&vin=${value.vin}`)
        Toast.clear()
    }

    return (
        <div className='topbackGroupClocl'>
            <h3 style={{ marginLeft: 10 }}>车辆合约检查</h3>
            <Form
                form={form}
                layout='vertical'
                initialValues={{ isTencent: false }}
                onFinish={onFinish}
                footer={
                    <>
                        <Space justify='center' className='controltBtnWrap' >
                            <Button block type='submit' size="large" color='primary' className='butSub'>
                                提交
                            </Button>
                            <Button block size="large" type='reset' className='butRest'>
                                重置
                            </Button>
                        </Space>
                    </>
                }

            >
                <Form.Item label='车牌号' name='plateNo' rules={[{ max: 20, message: '关键字过长' }]}>
                    <Input placeholder='请输入车牌号' />
                </Form.Item>
                <Form.Item label='Vin' name='vin' rules={[{ max: 50, message: '关键字过长' }]}>
                    <Input placeholder='请输入车辆Vin' />
                </Form.Item>
            </Form>
        </div>
    )
}
export default Contract